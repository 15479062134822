<template>
    <div>
        <loading-card :loading="!loaded">
            <div class="card-header">{{ $t('crud.transporters._show') }}</div>
            <div class="card-body">
                <transporters-form-component @submit="updateFromRoute" :record="record"></transporters-form-component>
            </div>
        </loading-card>
        <div class="card">
            <div class="card-header">{{ $t('crud.delivery_dates.title' ) }}</div>
            <div class="card-body">
                <button class="btn btn-sm btn-outline-secondary" v-if="!load_delivery_dates" @click="load_delivery_dates = true">{{ $t('crud.delivery_dates.load_available_dates' ) }}</button>
                <delivery-dates-component v-if="selected_dates && load_delivery_dates" @change="sendDates" :selected-dates="selected_dates"></delivery-dates-component>
            </div>
        </div>
    </div>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import TransportersFormComponent from './Form';
    import DeliveryDatesComponent from '../_shared/DeliveryDates';
    import ZoneRegionsComponent from './../_shared/ZoneRegions';

    export default {
        name: 'transporters-show-component',
        mixins: [functions],
        components: {TransportersFormComponent, DeliveryDatesComponent, ZoneRegionsComponent},
        data() {
            return {
                crud: config('transporters'),
                selected_dates: undefined,
                load_delivery_dates: false
            };
        },
        mounted() {
            this.$on('loaded', (record) => {
                this.selected_dates = record.delivery_dates.map(delivery_date => this.$moment(delivery_date.availability_date).format('DD-MM-YYYY'));
            });
        },
        methods: {
            /**
             * Send selected dates to the backend. Function is debounced for 1 second so that it doesnt keep
             * sending dates for every click.
             */
            sendDates: debounce(function(dates) {
                this.$http.put('/transporters/' + this.record.id + '/delivery-dates', {
                    dates: dates
                }).then(() => {
                    this.$toastr.s('Beschikbare data bijgewerkt');
                });
            }, 1000)
        }
    }
</script>
