<template>
    <div class="row">
        <div class="col-3" v-for="index in [0,1,2,3,4,5,6,7,8,9,10,11]">
            <div class="card" style="margin: 5px;">
                <div class="datepicker" :ref="'datepicker' + index"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['selectedDates'],
        data() {
            return {
                datepickers: [],
                selected_dates: []
            };
        },
        mounted() {
            this.selected_dates = this.selectedDates;
            this.selected_dates.sort();
            this.$nextTick(() => {
                for(let i = 0; i < 12; i++) {
                    this.loadDatePicker(i);
                }
            });
        },
        methods: {
            loadDatePicker(i) {
                this.datepickers[i] = $(this.$refs['datepicker' + i]);
                this.datepickers[i].datetimepicker({
                    inline: true,
                    format: 'DD-MM-YYYY',
                    locale: window.locale,
                    allowMultidate: true,
                    useCurrent: false,
                    calendarWeeks: true
                });
                // Preselect dates.
                this.selected_dates.forEach((date, index) => {
                    this.datepickers[i].data('datetimepicker').date(date, index);
                });
                this.datepickers[i].data('datetimepicker')._viewDate.month(i);
                this.datepickers[i].data('datetimepicker')._fillDate();
                this.datepickers[i].data('datetimepicker')._viewUpdate({});

                this.datepickers[i].on('change.datetimepicker', (e) => {
                    if(e.oldDate) {
                        let index = this.selected_dates.indexOf(e.oldDate.format('DD-MM-YYYY'));
                        if(index > -1) {
                            this.selected_dates.splice(index, 1);
                        }
                    }
                    if(e.date) {
                        this.selected_dates.push(e.date.format('DD-MM-YYYY'));
                    }

                    this.$emit('change', this.selected_dates);
                });
            },

        }
    }
</script>

<style>
    /* Disable next/prev buttons and overlapping dates in month view. */
    .datepicker .prev, .datepicker .next, .day.new, .day.old {
        visibility: hidden;
    }
</style>
