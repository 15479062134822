<template>
    <table class="table table-sm">
        <tr>
            <th>Postcode Start</th>
            <th>Postcode Eind</th>
            <th>Type</th>
            <th style="width: 1px;" class="text-right">
                <button @click="show_new_zone_region = !show_new_zone_region"
                        class="btn btn-sm btn-outline-secondary"><i class="fa fa-plus"></i>
                </button>
            </th>
        </tr>
        <transition name="fade">
            <tr v-if="show_new_zone_region">
                <td>
                    <form id="add-zone-region-form" @submit.prevent="addZoneRegion(new_zone_region)"></form>
                    <b-form-input size="sm" v-model="new_zone_region.region_start" required form="add-zone-region-form"></b-form-input>
                </td>
                <td>
                    <b-form-input size="sm" v-model="new_zone_region.region_end" form="add-zone-region-form"></b-form-input>
                </td>
                <td>
                    <b-form-select size="sm" v-model="new_zone_region.inclusive" required form="add-zone-region-form">
                        <option :value="1">{{ $t('crud.zone_regions.inclusive') }}</option>
                        <option :value="0">{{ $t('crud.zone_regions.exclusive') }}</option>
                    </b-form-select>
                </td>
                <td>
                    <loading-button class="btn btn-sm btn-outline-secondary" form="add-zone-region-form"
                            type="submit"

                            :title="$t('crud.save')"
                            :loading="loading_new_zone_region"><i class="fa fa-save"></i></loading-button>
                </td>
            </tr>
        </transition>
        <tr v-for="(zone_region, index) in record.zone_regions">
            <td>{{ zone_region.region_start }}</td>
            <td>{{ zone_region.region_end }}</td>
            <td>{{ zone_region.inclusive ? $t('crud.zone_regions.inclusive') : $t('crud.zone_regions.exclusive') }}</td>
            <td class="text-right">
                <loading-button class="btn btn-sm btn-outline-danger"

                        :loading="loading_zone_regions[index]"
                        @click="deleteZoneRegion(zone_region, index)"
                        :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
            </td>
        </tr>
    </table>
</template>


<script>
    export default {
        props: ['record', 'type'],
        data() {
            return {
                zone_regions: undefined,
                new_zone_region: {
                    morphable_id: this.record.id,
                    morphable_type: this.mapMorphableType(this.type),
                    region_start: undefined,
                    region_end: undefined,
                    inclusive: 1
                },
                show_new_zone_region: false,
                loading_new_zone_region: false,
                loading_zone_regions: [],
            };
        },
        methods: {
            /**
             * Get the morphable_type table property based on the type property passed to this instance.
             * @param {String} type
             * @return {String}
             */
            mapMorphableType(type)
            {
                switch(type) {
                    case 'zones':
                        return 'App\\Models\\Zone';
                    case 'transporters':
                        return 'App\\Models\\Transporter';
                    case 'transporter_zones':
                        return 'App\\Models\\TransporterZone';
                }
            },
            addZoneRegion(zone_region) {
                this.loading_new_zone_region = true;
                return this.$http.post(`zone-regions`, zone_region)
                    .then((response) => {
                        this.$emit('created', response.data);
                        // Reset object because it has been saved.
                        this.new_zone_region = Object.assign(this.new_zone_region, {
                            region_start: undefined,
                            region_end: undefined,
                            inclusive: 1
                        });
                        this.loading_new_zone_region = false;
                        this.show_new_zone_region = false;
                    }).catch(() => {
                        this.loading_new_zone_region = false;
                    });
            },
            deleteZoneRegion(zone_region, index) {
                if(confirm(this.$t('notifications.confirm_deletion', { object: this.$t('crud.zone_regions._singular') }))) {
                    this.$set(this.loading_zone_regions, index, true);
                    return this.$http.delete('zone-regions/' + zone_region.id).then(() => {
                        this.$emit('deleted', zone_region);
                        this.$set(this.loading_zone_regions, index, false);
                    }).catch(() => {
                        this.$set(this.loading_zone_regions, index, false);
                    });
                }
            },
        }
    }
</script>
