<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.transporters.name')">
            <b-form-input v-model="record.name" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.transporters.dispatch_key')">
            <b-form-input v-model="record.name_key" :disabled="!!record.id"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.transporters.logo')">
            <b-form-input v-model="record.logo" :disabled="!!record.id"></b-form-input>
        </b-form-group>
        <div class="row">
            <div class="col">
                <b-form-group :label="$t('crud.transporters.limit_cart_half_euro')">
                    <b-form-input v-model="record.limit_cart_half_euro"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group :label="$t('crud.transporters.limit_cart_euro')">
                    <b-form-input v-model="record.limit_cart_euro"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group :label="$t('crud.transporters.limit_cart_block')">
                    <b-form-input v-model="record.limit_cart_block"></b-form-input>
                </b-form-group>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <b-form-group :label="$t('crud.transporters.limit_transport_half_euro')">
                    <b-form-input v-model="record.limit_transport_half_euro"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group :label="$t('crud.transporters.limit_transport_euro')">
                    <b-form-input v-model="record.limit_transport_euro"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group :label="$t('crud.transporters.limit_transport_block')">
                    <b-form-input v-model="record.limit_transport_block"></b-form-input>
                </b-form-group>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                {{ $t('crud.transporters.restrictions') }}
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <b-form-group :label="$t('crud.transporters.max_pallets_per_customer_per_day')"
                                      :description="$t('crud.transporters.max_pallets_per_customer_per_day_description')">
                            <b-form-input v-model="record.max_pallets_per_customer_per_day"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group :label="$t('crud.transporters.max_pallets_per_day')"
                                      :description="$t('crud.transporters.max_pallets_per_day_description')">
                            <b-form-input v-model="record.max_pallets_per_day"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group :label="$t('crud.transporters.max_weight_per_customer')"
                                      :description="$t('crud.transporters.max_weight_per_customer_description')">
                            <b-form-input v-model="record.max_weight_per_customer"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <b-form-checkbox value="1" unchecked-value="0" v-model="record.am_shipping_possible">
                    {{ $t('crud.transporters.am_shipping_possible') }}
                </b-form-checkbox>
            </div>
        </div>
        <b-form-group :label="$t('crud.transporters.sorting_order')"
                      :description="$t('crud.transporters.sorting_order_description')">
            <b-form-input v-model="record.sorting_order" type="number" required></b-form-input>
        </b-form-group>
        <button class="btn btn-primary" type="submit">{{ $t('crud.save') }}</button>
    </form>
</template>

<script>
    export default {
        name: 'transporters-form-component',
        props: ['record'],
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>
